import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, Typography, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';

const BroadcastDialog = ({ open, onClose, onSchedule, scheduledTime, setScheduledTime }) => {
  const handleDateChange = (newValue) => {
    const zonedDate = DateTime.fromJSDate(new Date(newValue)).toUTC();
    setScheduledTime(zonedDate.toJSDate());
  };

  const now = DateTime.now();
  const minDateTime = now;
  const maxDateTime = now.plus({ days: 30 });
  const defaultDateTime = scheduledTime ? DateTime.fromJSDate(new Date(scheduledTime)) : now;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Schedule Broadcast
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Your timezone: {now.zoneName}
        </Typography>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} fullWidth />}
          label="Select Date and Time"
          value={defaultDateTime.toJSDate()}
          onChange={handleDateChange}
          minDateTime={minDateTime.toJSDate()} // Ensure only future dates and times can be selected
          maxDateTime={maxDateTime.toJSDate()} // Maximum 30 days from now
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onSchedule} color="primary">
          Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BroadcastDialog;