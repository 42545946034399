import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Typography, useMediaQuery, useTheme, IconButton, Avatar } from '@mui/material';
import { Dashboard, ExitToApp, Home as HomeIcon, Campaign, Settings, ContactMail, Menu as MenuIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const SideNavigationBar = ({ accountData, error }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isCollapsed = useMediaQuery('(max-width: 600px)');
  const [drawerOpen, setDrawerOpen] = useState(!isCollapsed);

  const handleLogout = async () => {
    const response = await fetch('/api/logout', {
      method: 'POST',
      credentials: 'include',
    });
    if (response.ok) {
      navigate('/');
    }
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const handleSupportClick = () => {
    window.open('https://m.me/408901325641502', '_blank');
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex">
      {isCollapsed && (
        <IconButton onClick={toggleDrawer} sx={{ position: 'fixed', top: 16, left: 16 }}>
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          width: drawerOpen ? 240 : 60,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerOpen ? 240 : 60,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          {accountData && (
            <Box display="flex" flexDirection="column" alignItems="center" p={2}>
              <Avatar src={accountData.photoUrl} sx={{ width: 48, height: 48, mb: 1 }} />
              <Typography variant="subtitle2" align="center">{accountData.name}</Typography>
            </Box>
          )}
          <List>
            {accountData && (
              <>
                <ListItem button onClick={() => navigate(`/dashboard/${accountData.id}`)} style={{ cursor: 'pointer' }}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={() => navigate(`/dashboard/${accountData.id}/broadcast`)} style={{ cursor: 'pointer' }}>
                  <ListItemIcon>
                    <Campaign />
                  </ListItemIcon>
                  <ListItemText primary="Broadcast" />
                </ListItem>
              </>
            )}
            <ListItem button onClick={() => navigate(`/dashboard/`)} style={{ cursor: 'pointer' }}>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button onClick={handleSupportClick} style={{ cursor: 'pointer' }}>
              <ListItemIcon>
                <ContactMail />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItem>
            <ListItem button onClick={handleSettingsClick} style={{ cursor: 'pointer' }}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout} style={{ cursor: 'pointer' }}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideNavigationBar;