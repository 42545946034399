import React, { useState, useEffect } from 'react';
import { Box, Button, Pagination, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import BroadcastForm from './BroadcastForm';
import BroadcastTable from './BroadcastTable';
import BroadcastDialog from './BroadcastDialog';
import BroadcastSnackbar from './BroadcastSnackbar';

const BROADCASTS_PER_PAGE = 30;

const Broadcast = ({ accountId }) => {
  const [broadcastName, setBroadcastName] = useState('');
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(new Date());
  const [formOpen, setFormOpen] = useState(false);
  const [userTimezone, setUserTimezone] = useState('America/Los_Angeles');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false); // Loading state
  const [broadcasts, setBroadcasts] = useState([]);
  const [selectedBroadcasts, setSelectedBroadcasts] = useState([]);
  const [page, setPage] = useState(1);

  const fetchBroadcasts = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/broadcast?accountId=${accountId}`);
      if (response.ok) {
        const data = await response.json();
        setBroadcasts(data);
      } else {
        setSnackbarMessage('Failed to fetch broadcasts.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBroadcasts();
  }, [accountId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('/api/user', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
        } else {
          setSnackbarMessage('Failed to fetch user data.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage(`Error: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchUserData();
  }, []);

  const handleBroadcastNow = async ({ broadcastName, message, imageFile }) => {
    setLoading(true); // Start loading spinner

    try {
      const formData = new FormData();
      formData.append('accountId', accountId);
      formData.append('broadcastName', broadcastName);
      formData.append('message', message);
      if (imageFile) {
        formData.append('imageFile', imageFile);
      }

      const res = await fetch('/api/broadcast', {
        method: 'POST',
        body: formData,
      });

      const data = await res.json();
      if (res.ok) {
        setSnackbarMessage('Broadcast message has been created successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setFormOpen(false); // Close the dialog after successful broadcasting
        resetForm(); // Reset form after successful broadcast
        fetchBroadcasts(); // Refresh broadcasts
      } else {
        setSnackbarMessage(`Error: ${data.error}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleBroadcastLater = ({ broadcastName, message, imageFile }) => {
    setBroadcastName(broadcastName);
    setMessage(message);
    setImageFile(imageFile);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  const handleScheduleBroadcast = async () => {
    setLoading(true); // Start loading spinner

    try {
      const formData = new FormData();
      formData.append('accountId', accountId);
      formData.append('broadcastName', broadcastName);
      formData.append('message', message);
      if (imageFile) {
        formData.append('imageFile', imageFile);
      }
      const currentTime = new Date();
      const delayInSeconds = Math.floor((scheduledTime - currentTime) / 1000); // Calculate delay in seconds
      formData.append('delayInSeconds', delayInSeconds);

      const res = await fetch('/api/broadcast', {
        method: 'POST',
        body: formData,
      });

      const data = await res.json();
      if (res.ok) {
        setSnackbarMessage('Broadcast message scheduled successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setFormOpen(false); // Close the dialog after successful broadcasting
        resetForm(); // Reset form after successful broadcast
        fetchBroadcasts(); // Refresh broadcasts
      } else {
        setSnackbarMessage(`Error: ${data.error}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Stop loading spinner
    }
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const resetForm = () => {
    setBroadcastName('');
    setMessage('');
    setImageFile(null);
  };

  const handleSelect = (event, id) => {
    if (id === 'all') {
      setSelectedBroadcasts(event.target.checked ? broadcasts.map((broadcast) => broadcast.id) : []);
    } else {
      setSelectedBroadcasts((prevSelected) =>
        prevSelected.includes(id) ? prevSelected.filter((broadcastId) => broadcastId !== id) : [...prevSelected, id]
      );
    }
  };

  const handleDelete = async () => {
    setLoading(true); // Start loading spinner

    try {
      await Promise.all(
        selectedBroadcasts.map(async (broadcastId) => {
          const res = await fetch(`/api/broadcast/${broadcastId}`, {
            method: 'DELETE',
            body: JSON.stringify({ accountId }),
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!res.ok) {
            throw new Error(`Failed to delete broadcast with ID: ${broadcastId}`);
          }
        })
      );

      setBroadcasts((prevBroadcasts) => prevBroadcasts.filter((broadcast) => !selectedBroadcasts.includes(broadcast.id)));
      setSelectedBroadcasts([]);
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedBroadcasts = broadcasts.slice((page - 1) * BROADCASTS_PER_PAGE, page * BROADCASTS_PER_PAGE);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={'en-US'}>
      <Box display="flex" flexDirection="column" alignItems="center" p={3}>
        <Typography variant="h4" gutterBottom>
          Broadcasts
        </Typography>
        <Box display="flex" justifyContent="flex-end" width="100%" mb={2}>
          <Button variant="contained" color="primary" onClick={handleFormOpen}>
            Create Broadcast
          </Button>
        </Box>
        <BroadcastTable
          broadcasts={paginatedBroadcasts}
          selectedBroadcasts={selectedBroadcasts}
          loading={loading}
          onSelect={handleSelect}
          onDelete={handleDelete}
          userTimezone={userTimezone}
        />
        <Pagination count={Math.ceil(broadcasts.length / BROADCASTS_PER_PAGE)} page={page} onChange={handlePageChange} sx={{ mt: 2 }} />
        <BroadcastForm
          open={formOpen}
          onClose={handleFormClose}
          onSubmit={handleBroadcastNow}
          onSchedule={handleBroadcastLater}
          loading={loading}
          userTimezone={userTimezone}
        />
        <BroadcastDialog
          open={openDialog}
          onClose={handleDialogClose}
          onSchedule={handleScheduleBroadcast}
          scheduledTime={scheduledTime}
          setScheduledTime={setScheduledTime}
          userTimezone={userTimezone}
          loading={loading}
        />
        <BroadcastSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default Broadcast;