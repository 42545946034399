import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button, CircularProgress } from '@mui/material';
import { DateTime } from 'luxon';

const BroadcastTable = ({ broadcasts, selectedBroadcasts, loading, onSelect, onDelete }) => {
  const userTimezone = DateTime.local().zoneName;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selectedBroadcasts.length > 0 && selectedBroadcasts.length < broadcasts.length}
                checked={broadcasts.length > 0 && selectedBroadcasts.length === broadcasts.length}
                onChange={(event) => onSelect(event, 'all')}
              />
            </TableCell>
            <TableCell>Broadcast Name</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Created Time</TableCell>
            <TableCell>Broadcasted</TableCell>
            <TableCell>Broadcasted Time</TableCell>
            <TableCell>Number of Successful Sends</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {broadcasts.map((broadcast) => {
            const localCreatedTime = DateTime.fromMillis(broadcast.created_time).setZone(userTimezone).toFormat('yyyy-MM-dd HH:mm z');
            const localBroadcastedTime = broadcast.broadcasted_time ? DateTime.fromMillis(broadcast.broadcasted_time).setZone(userTimezone).toFormat('yyyy-MM-dd HH:mm z') : '';

            return (
              <TableRow key={broadcast.id} selected={selectedBroadcasts.includes(broadcast.id)}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedBroadcasts.includes(broadcast.id)}
                    onChange={(event) => onSelect(event, broadcast.id)}
                  />
                </TableCell>
                <TableCell>{broadcast.broadcast_name}</TableCell>
                <TableCell>{broadcast.message}</TableCell>
                <TableCell>{localCreatedTime}</TableCell>
                <TableCell>{broadcast.has_broadcasted ? '✔️' : ''}</TableCell>
                <TableCell>{localBroadcastedTime}</TableCell>
                <TableCell>{broadcast.num_successful_sent}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Button onClick={onDelete} color="secondary" disabled={loading || selectedBroadcasts.length === 0}>
        {loading ? <CircularProgress size={24} /> : 'Delete Selected'}
      </Button>
    </TableContainer>
  );
};

export default BroadcastTable;