import React from 'react';
import { Button, Typography, Box, AppBar, Toolbar, Menu, MenuItem, Link, Tooltip } from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FlagIcon } from 'react-flag-kit'; // Import FlagIcon from react-flag-kit

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#ff4081',
    },
    text: {
      primary: '#000000',
      secondary: '#757575',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    divider: '#e0e0e0',
    grey: {
      200: '#eeeeee',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const RootContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start', // Adjusted to move content higher
  minHeight: '90vh',
  textAlign: 'center',
  padding: '20px',
  backgroundColor: theme.palette.background.default, // Different color for the main body
  width: '100%',
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: '20px',
  width: '250px',
  fontSize: '16px',
  backgroundColor: '#ffffff',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const LoginButton = ({ platform, url, Icon, iconColor }) => {
  const handleLogin = () => {
    window.location.href = url;
  };

  return (
    <StyledButton variant="contained" onClick={handleLogin} startIcon={<Icon style={{ color: iconColor, fontSize: '32px' }} />}>
      Get started with {platform}
    </StyledButton>
  );
};

const Header = ({ anchorEl, handleLanguageMenuOpen, handleLanguageMenuClose }) => (
  <AppBar position="sticky" color="default" elevation={4} sx={{ top: 0, backgroundColor: theme.palette.background.paper }}>
    <Toolbar>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Typography variant="h6" component="div" color="primary">
          Chat Success AI
        </Typography>
        <Box display="flex" alignItems="center">
          <Button
            variant="text"
            color="primary"
            sx={{
              cursor: 'pointer',
              marginRight: 2,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
            onClick={() => window.open('https://m.me/408901325641502', '_blank')}
          >
            Chat
          </Button>
          <Button
            variant="text"
            color="primary"
            sx={{
              cursor: 'pointer',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
            onClick={handleLanguageMenuOpen}
          >
            Language
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: 'none',
            }}
            onClick={() => window.location.href = '/auth/facebook'}
          >
            Login
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLanguageMenuClose}
          >
            <MenuItem onClick={handleLanguageMenuClose}>
              <FlagIcon code="US" size={24} style={{ marginRight: '8px' }} /> English
            </MenuItem>
            {/* Add more languages here */}
          </Menu>
        </Box>
      </Box>
    </Toolbar>
  </AppBar>
);

const Footer = () => (
  <Box component="footer" py={3} textAlign="center" borderTop={1} borderColor="grey.300" width="100%" bgcolor={theme.palette.background.paper}>
    <Typography variant="body2" color="textSecondary">
      &copy; 2024 Chat Success AI. All rights reserved.
    </Typography>
    <Box mt={1}>
      <Link href="/privacy" color="primary" underline="hover">
        Privacy Policy
      </Link>
      {' | '}
      <Link href="/terms-of-services" color="primary" underline="hover">
        Terms of Service
      </Link>
    </Box>
  </Box>
);

const LandingPage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLanguageMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: theme.palette.background.paper, minHeight: '100vh' }}>
        <Header
          anchorEl={anchorEl}
          handleLanguageMenuOpen={handleLanguageMenuOpen}
          handleLanguageMenuClose={handleLanguageMenuClose}
        />
        <RootContainer>
          <Box my={4} textAlign="center" py={5}>
            <Typography variant="h3" component="h1" gutterBottom color="primary">
              Supercharge Your Business with Facebook Marketing
            </Typography>
            <Typography variant="h5" component="p" gutterBottom color="textSecondary">
              We help you build subscribers and enable you to broadcast promotions and new updates to all your subscribers for free via Messenger or Instagram.
            </Typography>
            <Box mt={4} display="flex" justifyContent="center" gap={2}>
              <LoginButton platform="Facebook" url="/auth/facebook" Icon={FacebookIcon} iconColor="#1877F2" />
              <LoginButton platform="Instagram" url="/auth/instagram" Icon={InstagramIcon} iconColor="#E4405F" />
            </Box>
          </Box>
        </RootContainer>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;