import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Broadcast from './Broadcast/Broadcast';
import Home from './Home'; // Import other components as needed

const AccountDetails = () => {
  const [data, setData] = useState({ pages: [], instagramBusinessAccounts: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetchedData = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  // Function to extract the accountId from the URL
  const extractAccountId = (path) => {
    const match = path.match(/\/dashboard\/(\d+)(\/|$)/);
    return match ? match[1] : null;
  };

  const accountId = extractAccountId(currentPath);
  const fetchAccountData = async () => {
    try {
      let response = await fetch(`/api/page/${accountId}`, { method: 'GET', credentials: 'include' });
      if (response.status === 404) {
        response = await fetch(`/api/instagram-business/${accountId}`, { method: 'GET', credentials: 'include' });
      }

      if (response.status === 401) {
        navigate('/');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch account data');
      }

      const data = await response.json();
      setData({
        id: data.id,
        name: data.name,
        photoUrl: data.photoUrl
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
      hasFetchedData.current = true;
    }
  };

  useEffect(() => {
    if (accountId && !hasFetchedData.current) {
      fetchAccountData();
    }
  }, [accountId, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Layout data={data} isLoading={isLoading} error={error} />}>
        <Route index element={<Home />} />
        <Route path="broadcast" element={<Broadcast accountId={accountId} />} />
        {/* Add more routes as needed */}
      </Route>
    </Routes>
  );
};

export default AccountDetails;