import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, Avatar, List, ListItem, ListItemIcon, ListItemText, Paper, Grid, FormControl, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import { Language, Person, Fingerprint, Email } from '@mui/icons-material';
import { Autocomplete, Popper } from '@mui/material';
import SideNavigationBar from './SideNavigationBar'; // Adjust the import path as necessary
import currencies from 'currency-codes/data'; // Ensure this package is installed

const UserSettings = () => {
  const [language, setLanguage] = useState('en');
  const [currency, setCurrency] = useState('USD');
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('/api/user', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setUserName(data.displayName);
          setUserId(data.id);
          setUserEmail(data.email);
          setUserPhoto(data.photoURL);
          setLanguage(data.language);
          setCurrency(data.currency);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setIsAuthenticated(false);
      }
    };

    fetchUserData();
  }, []);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleCurrencyChange = (event, newValue) => {
    setCurrency(newValue ? newValue.code : '');
  };
  
  const handleSaveChanges = async () => {
    try {
      const response = await fetch('/api/user', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ language, currency }),
      });
      if (response.ok) {
        setSnackbarMessage('Settings saved successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Failed to save settings.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Failed to save settings:', error);
      setSnackbarMessage('Failed to save settings.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display="flex">
      <SideNavigationBar />
      <Box display="flex" flexDirection="column" alignItems="center" p={3} flex={1}>
        <Paper elevation={3} sx={{ p: 3, width: '100%', maxWidth: 600 }}>
          {isAuthenticated && (
            <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
              <Avatar src={userPhoto} alt={userName} sx={{ width: 50, height: 50, mb: 2 }} />
              <Typography variant="h5" gutterBottom>
                Your Account Information
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText primary="Full Name" secondary={userName} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Fingerprint />
                  </ListItemIcon>
                  <ListItemText primary="Facebook User ID" secondary={userId} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText primary="Email Address" secondary={userEmail} />
                </ListItem>
              </List>
            </Box>
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography>Language</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl fullWidth margin="normal">
                <Select value={language} onChange={handleLanguageChange} startAdornment={<Language />}>
                  <MenuItem value="en">English</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Typography>Currency</Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                options={currencies}
                getOptionLabel={(option) => `${option.currency} (${option.code})`}
                value={currencies.find((c) => c.code === currency) || null}
                onChange={handleCurrencyChange}
                renderInput={(params) => <TextField {...params} label="Currency" variant="outlined" />}
                PopperComponent={(props) => <Popper {...props} placement="bottom-start" />}
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSaveChanges} sx={{ mt: 2 }}>
            Save Changes
          </Button>
        </Paper>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default UserSettings;