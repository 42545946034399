import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageUploader from './ImageUploader'; // Adjust the import path as necessary
import BroadcastDialog from './BroadcastDialog'; // Adjust the import path as necessary

const MAX_BROADCAST_NAME_LENGTH = 255;
const MAX_MESSAGE_LENGTH = 5000;

const BroadcastForm = ({ open, onClose, onSubmit, onSchedule, userTimezone }) => {
  const [broadcastName, setBroadcastName] = useState('');
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(new Date());

  const handleBroadcastNameChange = (event) => {
    setBroadcastName(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const validateFields = () => {
    if (broadcastName.length > MAX_BROADCAST_NAME_LENGTH) {
      alert(`Broadcast name cannot exceed ${MAX_BROADCAST_NAME_LENGTH} characters.`);
      return false;
    }
    if (message.length === 0 && !imageFile) {
      alert('Either message or image is required.');
      return false;
    }
    if (message.length > MAX_MESSAGE_LENGTH) {
      alert(`Message cannot exceed ${MAX_MESSAGE_LENGTH} characters.`);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit({ broadcastName, message, imageFile });
      handleClose();
    }
  };

  const handleSchedule = () => {
    if (validateFields()) {
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSchedule = () => {
    if (validateFields()) {
      onSchedule({ broadcastName, message, imageFile, scheduledTime });
      setDialogOpen(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setBroadcastName('');
    setMessage('');
    setImageFile(null);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setBroadcastName('');
      setMessage('');
      setImageFile(null);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          Create Broadcast
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Broadcast Name"
            value={broadcastName}
            onChange={handleBroadcastNameChange}
            fullWidth
            margin="normal"
            inputProps={{ maxLength: MAX_BROADCAST_NAME_LENGTH }}
          />
          <TextField
            label="Message"
            value={message}
            onChange={handleMessageChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            inputProps={{ maxLength: MAX_MESSAGE_LENGTH }}
            helperText={`${message.length}/${MAX_MESSAGE_LENGTH} characters`}
          />
          <ImageUploader setImage={setImageFile} />
        </DialogContent>
        <DialogActions>
          <Box sx={{ position: 'relative' }}>
            <Button onClick={handleSchedule} color="secondary">
              Broadcast Later
            </Button>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Button onClick={handleSubmit} color="primary">
              Broadcast Now
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <BroadcastDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSchedule={handleDialogSchedule}
        scheduledTime={scheduledTime}
        setScheduledTime={setScheduledTime}
      />
    </>
  );
};

export default BroadcastForm;