import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const TermsOfServices = () => {
  return (
    <Container maxWidth="md" style={{ padding: '20px' }}>
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to ChatSuccess! These terms and conditions outline the rules and regulations for the use of ChatSuccess's Website, located at www.chatsuccess.ai.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          1. Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing this website we assume you accept these terms and conditions. Do not continue to use ChatSuccess if you do not agree to take all of the terms and conditions stated on this page.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          2. Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Please refer to our <Link href="/privacy">Privacy Policy</Link> for information on how we collect, use, and disclose information from our users.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          3. License
        </Typography>
        <Typography variant="body1" paragraph>
          Unless otherwise stated, ChatSuccess and/or its licensors own the intellectual property rights for all material on ChatSuccess. All intellectual property rights are reserved. You may access this from ChatSuccess for your own personal use subjected to restrictions set in these terms and conditions.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          4. User Content
        </Typography>
        <Typography variant="body1" paragraph>
          In these Website Standard Terms and Conditions, "User Content" shall mean any audio, video, text, images or other material you choose to display on this Website. By displaying User Content, you grant ChatSuccess a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          5. Restrictions
        </Typography>
        <Typography variant="body1" paragraph>
          You are specifically restricted from all of the following:
        </Typography>
        <ul>
          <li>Publishing any Website material in any other media without prior written consent.</li>
          <li>Selling, sublicensing and/or otherwise commercializing any Website material.</li>
          <li>Publicly performing and/or showing any Website material.</li>
          <li>Using this Website in any way that is or may be damaging to this Website.</li>
          <li>Using this Website in any way that impacts user access to this Website.</li>
          <li>Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity.</li>
          <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website.</li>
          <li>Using this Website to engage in any advertising or marketing.</li>
        </ul>
        <Typography variant="h4" component="h2" gutterBottom>
          6. Limitation of liability
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall ChatSuccess, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. ChatSuccess, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          7. Indemnification
        </Typography>
        <Typography variant="body1" paragraph>
          You hereby indemnify to the fullest extent ChatSuccess from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          8. Severability
        </Typography>
        <Typography variant="body1" paragraph>
          If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          9. Variation of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          ChatSuccess is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          10. Assignment
        </Typography>
        <Typography variant="body1" paragraph>
          The ChatSuccess is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          11. Entire Agreement
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms constitute the entire agreement between ChatSuccess and you in relation to your use of this Website, and supersede all prior agreements and understandings.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          12. Governing Law & Jurisdiction
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms will be governed by and interpreted in accordance with the laws of the State of [Your State], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your State] for the resolution of any disputes.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfServices;