import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ImageUploader = ({ setImage }) => {
  const [imageFile, setImageFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      setErrorMessage('You can only upload one image.');
      return;
    }
    const file = acceptedFiles[0];
    setImageFile(file);
    setImage(file);
    setErrorMessage('');
  }, [setImage]);

  const handleRemoveImage = () => {
    setImageFile(null);
    setImage(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false // Only allow a single image
  });

  useEffect(() => {
    // Revoke the data URI to avoid memory leaks
    return () => {
      if (imageFile) {
        URL.revokeObjectURL(imageFile.preview);
      }
    };
  }, [imageFile]);

  return (
    <Box>
      <Box {...getRootProps()} sx={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <Typography variant="body1">Drag & drop an image here, or click to select an image</Typography>
      </Box>
      {errorMessage && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {errorMessage}
        </Typography>
      )}
      {imageFile && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Typography variant="h6">Image Preview:</Typography>
          <Box position="relative" mt={2}>
            <img
              src={URL.createObjectURL(imageFile)}
              alt="upload-preview"
              style={{ width: 200, height: 200, objectFit: 'cover' }}
            />
            <IconButton
              size="small"
              onClick={handleRemoveImage}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      {!imageFile && (
        <Typography variant="body2" color="textSecondary" mt={2}>
          No image uploaded.
        </Typography>
      )}
    </Box>
  );
};

export default ImageUploader;