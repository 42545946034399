import React, { useState, useEffect, useRef } from 'react';
import { Box, Avatar, Typography, Card, CardContent, Grid, Button, Tooltip, TextField, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SideNavigationBar from './SideNavigationBar';
import LoadingBar from 'react-top-loading-bar';
import { blue } from '@mui/material/colors';

const Dashboard = () => {
  const [data, setData] = useState({ pages: [], instagramBusinessAccounts: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const loadingBarRef = useRef(null);

  const fetchData = async () => {
    loadingBarRef.current.continuousStart();
    try {
      const response = await fetch('/api/dashboard', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result);
      setIsLoading(false);
      loadingBarRef.current.complete();
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      loadingBarRef.current.complete();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    setIsLoading(true);
    fetchData();
  };

  const handleConnectFacebook = () => {
    window.location.href = '/auth/facebook';
  };

  const handleConnectInstagram = () => {
    window.location.href = '/auth/instagram';
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPages = data.pages.filter(page => 
    page.page_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    page.id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredInstagramAccounts = data.instagramBusinessAccounts.filter(account => 
    account.ig_business_account_username.toLowerCase().includes(searchQuery.toLowerCase()) ||
    account.id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box display="flex">
      <LoadingBar color={blue[500]} ref={loadingBarRef} />
      <SideNavigationBar data={data} isLoading={isLoading} />
      <Box p={3} flexGrow={1}>
        <Box mb={3}>
          <Typography variant="h4" component="h1" align="left">
            Dashboard
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <TextField
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <SearchIcon position="start" />
                ),
              }}
              sx={{ width: '100%', maxWidth: 400 }}
            />
            <Box display="flex" alignItems="center">
              <Tooltip title="Reload all the pages and IG accounts">
                <IconButton color="primary" onClick={handleRefresh}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<FacebookIcon style={{ color: '#1877F2' }} />}
                onClick={handleConnectFacebook}
                sx={{ ml: 2 }}
              >
                Add Pages
              </Button>
              <Tooltip title="If you don't see your Instagram account, make sure it's converted to a creator/business Instagram account and linked to a Facebook page">
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<InstagramIcon style={{ color: '#E4405F' }} />}
                  onClick={handleConnectInstagram}
                  sx={{ ml: 2 }}
                >
                  Add Instagram Accounts
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        {isLoading ? null : (
          <Box>
            {filteredPages.length === 0 && filteredInstagramAccounts.length === 0 ? (
              <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  There are no pages or Instagram accounts connected.
                </Typography>
              </Box>
            ) : (
              <Box>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {filteredPages.map(page => (
                    <Grid item key={page.id} xs={12} sm={6} md={4} lg={3}>
                      <Link to={`/dashboard/${page.id}`} style={{ textDecoration: 'none' }}>
                        <Card sx={{ boxShadow: 1, borderRadius: 2, height: '100%', transition: 'transform 0.2s, box-shadow 0.2s', '&:hover': { transform: 'scale(1.05)', boxShadow: 6 } }}>
                          <CardContent sx={{ transition: 'box-shadow 0.2s', '&:hover': { boxShadow: 6 } }}>
                            <Box display="flex" justifyContent="center" mb={1}>
                              <Avatar
                                src={page.page_photo_url}
                                alt={page.page_name}
                                sx={{ width: 40, height: 40, borderRadius: 0 }}
                              />
                            </Box>
                            <Typography variant="subtitle1" component="h2" align="center">
                              {page.page_name}
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                              <FacebookIcon sx={{ mr: 1, color: '#3b5998' }} />
                              <Typography variant="body2">{page.id}</Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                  ))}
                  {filteredInstagramAccounts.map(account => (
                    <Grid item key={account.id} xs={12} sm={6} md={4} lg={3}>
                      <Link to={`/dashboard/${account.id}`} style={{ textDecoration: 'none' }}>
                        <Card sx={{ boxShadow: 1, borderRadius: 2, height: '100%', transition: 'transform 0.2s, box-shadow 0.2s', '&:hover': { transform: 'scale(1.05)', boxShadow: 6 } }}>
                          <CardContent sx={{ transition: 'box-shadow 0.2s', '&:hover': { boxShadow: 6 } }}>
                            <Box display="flex" justifyContent="center" mb={1}>
                              <Avatar
                                src={account.ig_business_photo_url}
                                alt={account.ig_business_account_username}
                                sx={{ width: 40, height: 40, borderRadius: 0 }}
                              />
                            </Box>
                            <Typography variant="subtitle1" component="h2" align="center">
                              {account.ig_business_account_username}
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                              <InstagramIcon sx={{ mr: 1, color: '#E1306C' }} />
                              <Typography variant="body2">{account.id}</Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;