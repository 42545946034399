import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideNavigationBar from './SideNavigationBar';

const Layout = ({ data, isLoading, error }) => {
  return (
    <Box display="flex">
      <SideNavigationBar accountData={data} isLoading={isLoading} error={error} />
      <Container sx={{ ml: 1, p: 0 }}>
        <Box display="flex" justifyContent="space-between" mt={1} mb={1}>
          <Box width="100%" sx={{ p: 0 }}>
            <Outlet />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Layout;